import { Divider, NativeSelect } from "@mui/material";
import { CountryCode, getCountries } from "libphonenumber-js";

interface CountryCodeSelectorProps {
  countryCode: CountryCode;
  onSelect: (countryCode: CountryCode) => void;
}

const coutries = getCountries();

const SupportedCountries = ["US", "CA", "GB", "AU"];
const otherCountries = coutries.filter((country) => !SupportedCountries.includes(country));

export const CountryCodeSelector = ({ countryCode, onSelect }: CountryCodeSelectorProps) => {
  return (
    <NativeSelect
      value={countryCode}
      onChange={(event) => onSelect(event.target.value as CountryCode)}
      sx={{
        "& .MuiNativeSelect-select": {
          width: "40px",
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
        "&:before, &:after": {
          borderBottom: "none",
        },
        "&:hover:before": {
          borderBottom: "none !important",
        },
      }}
    >
      {SupportedCountries.map((supportedCountry) => (
        <option key={supportedCountry} value={supportedCountry}>
          {supportedCountry}
        </option>
      ))}
      <Divider />
      {otherCountries.map((otherCountry) => (
        <option key={otherCountry} value={otherCountry}>
          {otherCountry}
        </option>
      ))}
    </NativeSelect>
  );
};
